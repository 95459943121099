import React, { useEffect, useState } from "react";
import logo from "../../../assets/logo/Laughter_logo_white_optimized.png"
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormControl, MenuItem, Select } from "@mui/material";

const HeaderComponent: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const imprintPath = "/terms_condition/imprint";
    const dataProductionPath = "/terms_condition/dataproduction";
    const conditionPath = "/terms_condition/conditions";
    const isImprintPage = 
        location.pathname === imprintPath || 
        location.pathname === dataProductionPath || 
        location.pathname === conditionPath;

    const [isSticky, setIsSticky] = useState(false);
    const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 40) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        }; 
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleMobileNavToggle = () => {
        setIsMobileNavOpen(!isMobileNavOpen);
    };

    const getActiveClass = (hash: string) => {
        return location.hash === hash ? "active" : "";
    };

    //  const [isHide, setIsHide] = useState(false);
    const [language, setLanguage] = useState<string>('en'); // Default language

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
    };
    
    return(
        //   <!-- ======= Header ======= -->
    <header id="header" className={`fixed-top d-flex align-items-center ${isSticky ? "fixed-top sticky header-scrolled" : "fixed-top"} ${isImprintPage && "bg-black"}`}>
        <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
            {/* <!-- Uncomment below if you prefer to use an image logo --> */}
            <a href="#" className="logo me-auto me-lg-0">
                <img src={logo} alt="logo" className="img-fluid" 
                style={{objectFit:'contain',
                    maxHeight:"150px",
                    maxWidth:"150px"
                    //  height:"120px",
                // backgroundSize:"cover"                    
                }} 
                loading="lazy"/>
            </a>

            <div>
                
                <nav id="navbar" 
                // className="navbar order-last order-lg-0"
                className={`navbar order-last order-lg-0 d-flex justify-content-start ${isMobileNavOpen && "navbar-mobile"}`}
                >
                    <ul>
                        <li><a className={`nav-link scrollto ${getActiveClass("#hero")}`} href="#hero" onClick={()=>navigate("/#hero")}><h3>{t('menu_home')}</h3></a></li>
                        <li><a className={`nav-link scrollto ${getActiveClass("#about")}`} href="#about" onClick={()=>navigate("/#about")}><h3>{t('menu_about')}</h3></a></li>
                        <li><a className={`nav-link scrollto ${getActiveClass("#results")}`} href="#results" onClick={()=>navigate("/#result")}><h3>{t('menu_result')}</h3></a></li>
                        <li><a className={`nav-link scrollto ${getActiveClass("#list-module")}`} href="#list-module" onClick={()=>navigate("/#list-module")}><h3>{t('menu_module')}</h3></a></li>
                        <li><a className={`nav-link scrollto ${getActiveClass("#testimonials-ratings")}`} href="#testimonials-ratings" onClick={()=>navigate("/#testimonials-ratings")}><h3>{t('menu_testimonials')}</h3></a></li>
                        <li><a className="nav-link" onClick={()=>navigate("/login")} style={{cursor:'pointer'}}>
                            <h3>Login</h3>
                        </a></li>
                    </ul>
                    <i
                        className={`bi bi-list mobile-nav-toggle ${isMobileNavOpen ? "bi-x" : "bi-list"}`} onClick={handleMobileNavToggle}
                    ></i>
                </nav>
            </div>
                <div 
                    className="languages d-flex justify-content-end"
                >
                    <FormControl variant="standard" sx={{minWidth: 120 }}>
                        <Select
                            value={language}
                            onChange={(e) => changeLanguage(e.target.value as string)}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Language Selection' }}
                            sx={{
                                color: "white", // Text color
                                '& .MuiSelect-select': {
                                    display: 'flex',
                                    alignItems: 'center',
                                },
                            }}
                        >
                        <MenuItem value="en">
                            <img
                                src="https://flagcdn.com/w20/us.png" // US flag
                                alt="US Flag"
                                style={{ marginRight: 8 }}
                            />
                            English
                        </MenuItem>
                        <MenuItem value="de">
                            <img
                                src="https://flagcdn.com/w20/de.png" // German flag
                                alt="German Flag"
                                style={{ marginRight: 8 }}
                            />
                            Deutsch
                        </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            {/* <!-- .navbar --> */}
            {/* <a href="#book-now" className="book-a-table-btn scrollto d-none d-lg-flex" onClick={()=>navigate("/#book-now")}>
                <h6>{t("book_now")}</h6>
            </a> */}


        </div>
    {/* <!-- End Header --> */}
    </header>
    )
}

export default HeaderComponent;