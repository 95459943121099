import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import TobBarComponent from '../TobBar/TobBar';
import HeaderComponent from '../Header/Header';
import FooterComponent from '../Footer/FooterComponent';
import { useMediaQuery, useTheme } from '@mui/material';

const MainLayout: React.FC = () => {
    const location = useLocation();
    const path = ["/terms_condition/imprint", "/terms_condition/dataproduction", "/terms_condition/conditions"];
    const isCheckColor = (): boolean => {
        return path.includes(location.pathname);
    }
    const theme = useTheme();
  const isLaptop = useMediaQuery(theme.breakpoints.up('lg'));   // Large screens
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));  // Tablets
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));  // Small screens

  const getMarginTop = () => {
    if (isLaptop && isCheckColor()) return '10%';
    if (isTablet && isCheckColor()) return '25%';
    if (isMobile && isCheckColor()) return '45%';
    return '0'; // Fallback value
  };
  return (
    <div>
      {/* <TobBarComponent color={isCheckColor(location.pathname)} /> */}
      <HeaderComponent />
      <div 
        style={{ marginTop: getMarginTop(), 
          padding: isCheckColor() ? '0 10% 0 10%' : '0'
        }}
    >
        <Outlet />
      </div>
      <FooterComponent />
    </div>
  );
};

export default MainLayout;
