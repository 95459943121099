import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { courseList } from "../../type";

interface initial {
  language:string;
  selectedCourse:courseList|null;
}
export const initialState:initial = {
    language: "english",
    selectedCourse: null,
};

const dataSlice = createSlice({
  name: "data",
  initialState: initialState,
  reducers: {
    setAdminLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setCourseListUpdate : (state,action:PayloadAction<courseList | null>) => {
      state.selectedCourse = action.payload;
    }
  },
});

export const { setAdminLanguage,setCourseListUpdate } = dataSlice.actions;
export const dataReducer = dataSlice.reducer;
export default dataSlice;