import * as React from 'react';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Chip, Divider } from '@mui/material';
import CourseList from './CourseList';
import MyCourse from './MyCourse';
import CreateCourse from './CreateCourse';
import { Container } from 'react-bootstrap';
import MyGetCourse from './MyGetCourse';
import { courseData } from '../../../Const/StateVariable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../Redux/store/ReduxStore';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function CourseMain() {

  const role = useSelector((state:RootState)=>state.auth.role);

  const [selectedReport, setSelectedReport] = React.useState<string>('All course');
  const [show,setShow] = React.useState(false);
  const [showContent,setShowContent] = React.useState(true);
  const [courseDetails, setCourseDetails] = React.useState(courseData);

  const AddCourse = () => {
    setShow(!show);
    setShowContent(true);
  }

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setSelectedReport(newValue);
    };

  return (
    <>
        {
          role === "Admin" ? (
            <div className='d-flex justify-content-end mt-5'>
              <Chip variant='outlined' title={!show ? "Main Course" : "Back"} color='warning' label={!show ? "Main Course" : "Back"} style={{cursor:'pointer'}} onClick={AddCourse}/>
            </div>
          ) : (
            <div className='d-flex justify-content-end mt-5'>
              
            </div>
          )
        }
    {
      showContent ? (
        <>
          {
            !show ? (
              <Box
                sx={{
                    flexGrow:1 ,
                    maxWidth: { xs: 320, sm: 780 }, 
                    bgcolor: 'background.paper',borderRadius:'10px', marginBottom:'13x',
                    marginTop: { xs: '10%', sm: '2%'} // Increase marginTop for mobile
                }}
                className={'fade-in'}
              >
                <Tabs
                  value={selectedReport}
                  onChange={handleChange}
                  indicatorColor="primary"
                  variant='scrollable'
                  scrollButtons
                  aria-label="visible arrows tabs example"
                  sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                  },
                  }}
                  textColor="primary"
                  centered
                >
                    <Tab label="All courses" value="All course" />
                    <Tab label="My Courses" value="My course"/>
                </Tabs>
              </Box>
            ) : (
              <div className='p-3'>
                <CreateCourse setShow={setShow}/>
              </div>
            )
          }
            {
              (selectedReport === "All course" && !show) &&(
                <CourseList />
              )
            }
            {
              (selectedReport === "My course" && !show) &&(
                <MyCourse setCourseDetails={setCourseDetails} setShowContent={setShowContent}/>
              )
            }
        </>
      ) 
        : 
      (
        <Container fluid>
          <MyGetCourse setShowContent={setShowContent} courseData={courseDetails} />
        </Container>
      )
    }
    </>
  );
}
