import * as React from 'react';
import { courseList } from '../../../type';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../Redux/CommonSlice';
import { createAmountTransaction, getListOfCourse, getPaymentLinkURL } from '../../../Const';
import { CustomMessage, Loader } from '../../../Const/Spinner';
import { enqueueSnackbar } from 'notistack';
import { RootState } from '../../../Redux/store/ReduxStore';
import { decodeBase64 } from '../../../Const/const';
import dayjs from 'dayjs';
import { Card, CardContent, CardMedia, Container, Grid, Typography, CardActions, Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { styled } from '@mui/system';
import { REDIRECT_URL, STREAM_IMAGE_GET } from '../../../Const/APIPath';
import PayPalComponent from '../../../Components/Paypal/PayPalComponent';
import { Close } from '@mui/icons-material';
import StripePayment from '../../../Components/Stripe/Stripe';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useLocation, useNavigate } from 'react-router-dom';
import { setCourseListUpdate } from '../../../Redux/Reducer/DataReducer';

const Div = styled('div')({
  flexGrow: 1,
  padding: '20px',
});

const StyledCard = styled(Card)({
  maxWidth: 345,
  margin: 'auto',
  transition: '0.3s',
  '&:hover': {
    boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
  },
  padding: '15px',
});

const Media = styled(CardMedia)({
  height: 140,
});

const Footer = styled(CardActions)({
  textAlign: 'center',
  padding: '10px 0',
});

export default function CourseList() {
  const dispatch = useDispatch();
  const location = useLocation();
  const {search} = useLocation();

  const loading = useSelector((state: RootState) => state.common.data.loading);
  const selectedCourse = useSelector((state: RootState) => state.dataReducer.selectedCourse);
  const user = useSelector((state:RootState)=>state.auth);
  const [courseList, setCourseList] = React.useState<courseList[]>([]);
  const [showPayPal, setShowPayPal] = React.useState(false);

  React.useEffect(() => {
    if (courseList.length === 0) {
      getCourseList();
    }
  }, [courseList]);

  const getCourseList = async () => {
    dispatch(setLoading(true));
    try {
      const response = await getListOfCourse(user.userId);
      const { data, status } = response;
      if (status.code === 200 && data?.length > 0) {
        setCourseList(data);
      } else {
        CustomMessage(status?.message, 'error', enqueueSnackbar);
      }
    } catch (error: any) {
      CustomMessage(error?.message, 'error', enqueueSnackbar);
    } finally {
      dispatch(setLoading(false));
    }
  };

  React.useEffect(() => {
    const search = window.location.search;
    if(search){
      const params = new URLSearchParams(search);
      const paymentStatus = params.get('payment_status');
  
      if (paymentStatus == 'success') {
        addTransaction();  // Update payment status in your system
      }
    }
  }, [location]);

  const handleBuyClick = async (course: courseList) => {
    localStorage.setItem('selectedCourseId', course.courseId.toString());
    dispatch(setCourseListUpdate(course));

    // setShowPayPal(true);// Store the courseId in localStorage

    dispatch(setLoading(true));
    try{
      const response = await getPaymentLinkURL();
      if(response.status.code === 200 && response.data != null){
        // Navigate to the payment link URL in the same tab
        window.location.assign(response.data.url); // Opens in the same tab
        // navigate(response.data.url);
      }else{
        CustomMessage(response.status.message,'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  };

  const handleClosePayPal = (paymentCheck:boolean) => {
    setShowPayPal(false);
    dispatch(setCourseListUpdate(null));
    if(paymentCheck){
      getCourseList();
    }
  };

  const handleBackdropClick = (event: { stopPropagation: () => void; }) => {
    // Prevent the dialog from closing on backdrop click
    event.stopPropagation();
  };

  const addTransaction = async () => {
    const payload = {
      userId: user?.userId,
      courseId: localStorage.getItem('selectedCourseId') ?? 0,
      orderId: "",
      amount: "",
      currency: "",
      payerName: "",
      details: ""
    }
    dispatch(setLoading(true));
    try{
      const response = await createAmountTransaction(payload);
      const { data, status } = response;
      if(status.code === 200){
        window.location.assign(REDIRECT_URL);
        CustomMessage("Your Transaction saved successfully",'success',enqueueSnackbar);
        getCourseList();
      }else{
        CustomMessage("Transaction success but error in save history",'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
      localStorage.removeItem('selectedCourseId');
    }
  }

  return (
    <>
    <Div>
      {loading && <Loader />}
      <Grid container spacing={3}>
        {courseList &&
          courseList.map((item, index) => (
            <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
              <StyledCard>
                <Media
                  // image={decodeBase64(item?.courseCoverPicture)}
                  image={STREAM_IMAGE_GET + item?.courseCoverPictureId}
                  title="Course Cover"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {item.courseName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <i className="far fa-clock"></i>{' '}
                    {item?.createdTime && dayjs(item?.createdTime).format('MMMM D, YYYY')}
                  </Typography>
                </CardContent>
                <Footer>
                  <Typography variant="body2" color="textSecondary">
                    Last update: {item?.updatedTime && dayjs(item?.updatedTime).format('MMMM D, YYYY HH:MM')}
                  </Typography>
                  <Button variant="contained" color="primary"
                    onClick={() =>{
                      item.isPaid ? alert("Purchased") : handleBuyClick(item);
                    }}>
                    {item.isPaid ? "Purchased" : "Buy"}
                  </Button>
                </Footer>
              </StyledCard>
            </Grid>
          ))}
      </Grid>

    </Div>
      {/* PayPal Dialog */}
      <Dialog 
        open={showPayPal} 
        fullWidth={true}
        fullScreen
        onClose={(event, reason) => {
          // Prevent the dialog from closing on backdrop click
          if (reason !== 'backdropClick') {
            handleClosePayPal(false);
          }
        }}
        BackdropProps={{
          onClick: handleBackdropClick,
        }}
        // maxWidth="sm"
        maxWidth="lg" // Change this to 'sm', 'md', 'lg', 'xl' or set custom width below
        sx={{
          '& .MuiDialog-paper': {
            width: '100%', // Custom width percentage or fixed pixel value
            // maxWidth: '800px', // Set a maximum width for the dialog
          },
        }}
      >
        <DialogTitle sx={{
            display:'flex',
            justifyContent:'space-between',
            outline:'none'
          }}
        >
          {/* <div>Pay - &euro;111</div> */}
          <div style={{cursor:"pointer", color:'red', outline:'none'}} onClick={()=>handleClosePayPal(false)}><Close /></div></DialogTitle>
        <DialogContent>
          {/* {selectedCourse && (
            <StripePayment
              amount={111} // Amount in cents, e.g., 1500 cents = $15
              currency="eur" // Set currency type, default is 'usd'
              onSuccess={handlePaymentSuccess}
              onError={handlePaymentError}
            />
          )} */}
        </DialogContent>
      </Dialog>
    </>
  );
}