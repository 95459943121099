import React, { SetStateAction, useRef, useState } from "react";
import { CourseDefault } from "../../../type";
import { List, ListItem, ListItemText, Container, Paper, Typography, Box, Chip, IconButton, Drawer } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { blue, grey } from "@mui/material/colors";
import { CustomMessage, Loader } from "../../../Const/Spinner";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "../../../Redux/CommonSlice";
import { RootState } from "../../../Redux/store/ReduxStore";
import { getStreamVideoURL } from "../../../Const";
import { decodeBase64 } from "../../../Const/const";
import { STREAM_IMAGE_GET, STREAM_VIDEO_GET } from "../../../Const/APIPath";
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import MenuClose from '@mui/icons-material/MenuOpen';
import audioEnglishSrc from "../../../assets/yoga_nithra/Yoga_Nidra_Englisch.mp3"
import audioGermanSrc from "../../../assets/yoga_nithra/Yoga Nidra.mp3"
import englishAffirmation from "../../../assets/pdf/YOUR_AFFIRMATION.pdf"
import germanAffirmation from "../../../assets/pdf/german_affirmation.pdf"
import germanLaughterYogaCharacteristics from "../../../assets/pdf/Aufgabe- Die einzigartigen Merkmale des LY.pdf"
import englishLaughterYogaCharacteristics from "../../../assets/pdf/Englisch - Aufgabe Die einzigartigen Merkmale des LY .pdf"
import { PDFDocument } from "pdf-lib";


const Content = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: 'white',
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(3),
    // width: '75%',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    width: '100%',
  },
}));

const ListItemStyled = styled(ListItem)(({ theme }) => ({
  '&:hover': {
    backgroundColor: blue[50],
  },
  '&.Mui-selected': {
    backgroundColor: blue[100],
    '&:hover': {
      backgroundColor: blue[200],
    },
  },
}));

interface MyGetCourseProps {
  courseData: CourseDefault;
  setShowContent: React.Dispatch<SetStateAction<boolean>>;
}

interface SidebarContentProps {
  courseData: CourseDefault;
  setActiveItem: React.Dispatch<SetStateAction<number>>;
  activeItem: number;
  language: string;
  open: boolean;
  toggleSidebar: () => void;
}

const SidebarContent: React.FC<SidebarContentProps> = ({open, toggleSidebar, courseData, setActiveItem, activeItem, language }) => {
  const theme = useTheme();
  return (
    <Drawer
      // variant={useMediaQuery(theme.breakpoints.up('md')) ? "persistent" : "temporary"}
      open={open}
      onClose={toggleSidebar} 
      sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: useMediaQuery(theme.breakpoints.up('md')) ? 360 : '80%', // Full width on small screens, fixed width on larger screens
            maxWidth: '100%', // Ensure it doesn't exceed the screen width
            height: '80%', // Fill the available height
            marginTop: useMediaQuery(theme.breakpoints.up('md')) ? theme.spacing(16) : theme.spacing(17), // Adjust margin based on screen size
            boxSizing: 'border-box', // Ensure padding and border are included in the width and height
            overflowY: 'auto', // Enable scrolling if content overflows
            transition: 'transform 0.3s ease-in-out', // Smooth slide effect for opening/closing
          },
      }}
    >
      <div onClick={toggleSidebar} style={{cursor:'pointer',display:'flex',justifyContent:"center",alignItems:'center'}}>
        <MenuClose titleAccess="Close" />
      </div>
      <List>
        {courseData?.content[language === 'english' ? 'english' : 'german']
        ?.slice()// Create a shallow copy to avoid mutating the original array
        ?.sort((a, b) => a.contentId - b.contentId) // Sort based on contentId
        ?.map(item => (
          <ListItemStyled 
            key={item?.contentId} 
            selected={item?.contentId === activeItem}
            onClick={() => {
              setActiveItem(item?.contentId);
              toggleSidebar();
            }}
          >
            <ListItemText primary={`${item.contentId} - ${item?.contentName}`} />
          </ListItemStyled>
        ))}
      </List>
    </Drawer>
  );
};

const VideoContent: React.FC<{ contentFileId: number; courseId: number; contentId: number; posterURL: string; }> = ({
  contentFileId,
  courseId,
  contentId,
  posterURL
}) => {
  const loading = useSelector((state: RootState) => state.common.data.loading);
  const videoRef = useRef<HTMLVideoElement>(null);

  return (
    <>
      {loading && <Loader />} {/* Assuming Loader component is defined */}
      <video
        ref={videoRef}
        poster={posterURL}
        width="100%"
        height="auto"
        controls
        src={STREAM_VIDEO_GET + contentFileId}
        controlsList="nodownload"
        style={{ maxWidth: '100%' }}
      />
    </>
  );
};

const MyGetCourse: React.FC<MyGetCourseProps> = ({ courseData, setShowContent }) => {
  const [activeItem, setActiveItem] = useState<number>(1);
  const language = useSelector((state: RootState) => state.dataReducer.language);
  const theme = useTheme();
  const loading = useSelector((state: RootState) => state.common.data.loading);
  const [sidebarOpen, setSidebarOpen] = useState(false); // Initially closed on small screens

  const toggleSidebar = () => {
    setSidebarOpen((prev) => !prev);
  };

  const [pdfBlobUrls, setPdfBlobUrls] = useState<{ [key: string]: string | null }>({
    12: null,
    "12_german": null,
    6: null,
    "6_english": null,
  });

  // Function to load a PDF and return a Blob URL
  const loadPdf = async (url: string) => {
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  };

  // Loading all PDFs dynamically
  React.useEffect(() => {
    const loadAllPdfs = async () => {
      try {
        // Mapping of content IDs and their respective URLs
        const pdfUrls = {
          "12": englishAffirmation,
          "12_german": germanAffirmation,
          "6": germanLaughterYogaCharacteristics,
          "6_english": englishLaughterYogaCharacteristics,
        };

        // Load all PDFs and set them in state
        const newPdfBlobUrls: { [key: string]: string | null } = {};

        for (const [contentId, url] of Object.entries(pdfUrls)) {
          const blobUrl = await loadPdf(url);
          newPdfBlobUrls[contentId] = blobUrl;
        }

        setPdfBlobUrls(newPdfBlobUrls);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    loadAllPdfs();
  }, []);

  const renderPdf = (contentId: number, language: string) => {
    const pdfUrl = contentId === 6 && language === "english" ? pdfBlobUrls["6_english"] : pdfBlobUrls[contentId];

    return (
      <div style={{ height: "600px", border: "1px solid #ccc" }}>
        {pdfUrl ? (
          <>
            <iframe
              src={pdfUrl}
              width="100%"
              height="600px"
              title="PDF Viewer"
              style={{ border: "none" }}
            />
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <a href={pdfUrl} download={`Course_${contentId}.pdf`} style={{ textDecoration: 'none' }}>
                <Chip color="primary" label="Download PDF" clickable />
              </a>
            </Box>
          </>
        ) : (
          <p>Loading PDF...</p>
        )}
      </div>
    );
  };

  return (
    <>
    {
      loading && <Loader />
    }
    <Box
        sx={{
          display: {
            xs: 'block', // Block layout for mobile view (xs and small screens)
            md: 'flex',  // Flex layout for medium and larger screens
          },
        }}
        className="mt-5"
      > 
      <div>
        <Chip className="mb-2" title="Back" variant="outlined" color="info" onClick={() => setShowContent(true)} label={"Back"} />
      </div>
      <Container>
        <Box sx={{display:'flex'}}>
          <SidebarContent open={sidebarOpen} toggleSidebar={toggleSidebar} courseData={courseData} setActiveItem={setActiveItem} activeItem={activeItem} language={language} />
          <div style={{ flex: 1 }}>
            <span onClick={toggleSidebar} style={{cursor:'pointer'}}>
              <MenuIcon titleAccess="Open"/>
            </span>
            {
              courseData && courseData.content && courseData.content[language === 'english' ? 'english' : 'german']?.length > 0 && (
                courseData.content[language === 'english' ? 'english' : 'german']
                  .filter(val => val.contentId === activeItem)
                  .map(val => (
                    <Content key={val.contentId}>
                      <Typography variant="h4" gutterBottom>
                        {val.contentName}
                      </Typography>
                      <div className="mb-3">
                        <VideoContent contentFileId={val.contentFileId} courseId={courseData.courseId}  contentId={val.contentId} 
                          // posterURL={decodeBase64(val.posterFilePath)} 
                          posterURL={STREAM_IMAGE_GET + val.posterPictureId}
                        />
                      </div>
                      <div>
                        {
                          val.contentId == 13 && (
                            <audio controls>
                                <source src={language === 'english' ? audioEnglishSrc : audioGermanSrc} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                          )
                        }
                      </div>
                      <Typography variant="body2">
                        <p dangerouslySetInnerHTML={{ __html: val.description }} />
                      </Typography>
                      <div>
                        {val.contentId === 12 && language === "english" && renderPdf(12, "english")}
                        {val.contentId === 12 && language === "german" && renderPdf(12, "german")}
                        {val.contentId === 6 && language === "german" && renderPdf(6, "german")}
                        {val.contentId === 6 && language === "english" && renderPdf(6, "english")}
                      </div>
                    </Content>
                  ))
              )
            }
          </div>
        </Box>
      </Container>
    </Box>
    </>
  );
};

export default MyGetCourse;
