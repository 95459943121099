import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Conditions: React.FC = () => {
    const {t} = useTranslation();
    return (
        <>
            {/* <div>
                <h1>{t("condition_main_head")}</h1>
                <h4>{t("condition_conclusion_head3")}</h4>
                <h4>{t("condition_main_head2")}</h4>
                <p>GURU & YOGI LIFESTYLE LLC<br />ALI FIRAT CIGDEM SR SOLE MBR<br />7901 4TH ST N STE 300<br />ST PETERSBURG, FL 33702</p>
                <p>{t("condition_main_head_p1")}</p>

                <p>{t("condition_main_head_p2")}</p>
                <p>{t("condition_main_head_p3")}</p>

                <p>{t("Condition_main_head_p4")}</p>
                <br></br>

                <h4>{t("condition_conclusion_head")}</h4>
                <p>{t("condition_conclusion_p")}</p>
                <br></br>

                <h4>{t("condition_withdra_head")}</h4>
                <p dangerouslySetInnerHTML={{__html: t("condition_withdra_p")}} />
                <br></br>

                <h4>{t("condition_Cancellation_head")}</h4>
                <p>{t("condition_Cancellation_p1")}</p>
                <p>{t("condition_Cancellation_p2")}</p>
                <p>{t("condition_Cancellation_p3")}</p>
                <p>{t("condition_Cancellation_p4")}</p>
                <p>{t("condition_Cancellation_p5")}</p>
                <br></br>

                <h4>{t("condition_Price_head")}</h4>
                <p dangerouslySetInnerHTML={{__html:t("condition_Price_p")}} />
                <br></br>

                <h4>{t("condition_Delivery_head")}</h4>
                <p dangerouslySetInnerHTML={{__html: t("condition_Delivery_P")}} />
                <br></br>

                <h4>{t("condition_Payment_head")}</h4>
                <p dangerouslySetInnerHTML={{__html: t("condition_Payment_p")}} />

                <br></br>
                <h4>{t("condition_Retention_head")}</h4>
                <p>{t("condition_Retention_p")}</p>
                <br></br>

                <h4>{t("condition_Dispute_head")}</h4>
                <p>{t("condition_Dispute_p")}</p>
            </div> */}

            <Container sx={{ padding: "2rem 1rem" }}>
                {/* Header */}
                <Typography variant="h4" align="center" gutterBottom>
                    {t("AGB.head")}
                </Typography>

                {/* Section 1: Scope */}
                <Box sx={{ marginBottom: "1.5rem" }}>
                    <Typography variant="h5" gutterBottom>
                    {t("AGB.head1")}
                    </Typography>
                    <Typography variant="body1" dangerouslySetInnerHTML={{ __html: t("AGB.p1") }} />
                </Box>

                {/* Section 2: Provider’s Services */}
                <Box sx={{ marginBottom: "1.5rem" }}>
                    <Typography variant="h5" gutterBottom>
                    {t("AGB.head2")}
                    </Typography>
                    <Typography variant="body1">{t("AGB.p2")}</Typography>
                </Box>

                {/* Section 3: Conclusion of the Contract */}
                <Box sx={{ marginBottom: "1.5rem" }}>
                    <Typography variant="h5" gutterBottom>
                    {t("AGB.head3")}
                    </Typography>
                    <Typography variant="body1">{t("AGB.p3")}</Typography>
                </Box>

                {/* Section 4: Prices and Payment */}
                <Box sx={{ marginBottom: "1.5rem" }}>
                    <Typography variant="h5" gutterBottom>
                    {t("AGB.head4")}
                    </Typography>
                    <Typography variant="body1">{t("AGB.p4")}</Typography>
                </Box>

                {/* Section 5: Right of Withdrawal */}
                <Box sx={{ marginBottom: "1.5rem" }}>
                    <Typography variant="h5" gutterBottom>
                    {t("AGB.head5")}
                    </Typography>
                    <Typography variant="body1">{t("AGB.p5")}</Typography>
                </Box>

                {/* Section 6: Usage Rights */}
                <Box sx={{ marginBottom: "1.5rem" }}>
                    <Typography variant="h5" gutterBottom>
                    {t("AGB.head6")}
                    </Typography>
                    <Typography variant="body1">{t("AGB.p6")}</Typography>
                </Box>

                {/* Section 7: Liability and Warranty */}
                <Box sx={{ marginBottom: "1.5rem" }}>
                    <Typography variant="h5" gutterBottom>
                    {t("AGB.head7")}
                    </Typography>
                    <Typography variant="body1">{t("AGB.p7")}</Typography>
                </Box>

                {/* Section 8: Data Protection */}
                <Box sx={{ marginBottom: "1.5rem" }}>
                    <Typography variant="h5" gutterBottom>
                    {t("AGB.head8")}
                    </Typography>
                    <Typography variant="body1">{t("AGB.p8")}</Typography>
                </Box>

                {/* Section 9: Final Provisions */}
                <Box sx={{ marginBottom: "1.5rem" }}>
                    <Typography variant="h5" gutterBottom>
                    {t("AGB.head9")}
                    </Typography>
                    <Typography variant="body1">{t("AGB.p9")}</Typography>
                </Box>
                </Container>
        </>
    );
};

export default Conditions;
