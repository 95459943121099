import React from "react";
import { useTranslation } from "react-i18next";
import DataProductionImage from "../../assets/logo/dataProduction.png";
import { useNavigate } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Link, List, ListItem, ListItemText, Typography } from "@mui/material";

const Dataproduction: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const contentList: string[] = t("Privacy_policy.ContentTable.ContentList", { returnObjects: true }) as string[];
    const termList: string[] = t("Privacy_policy.ContentTable.term_list", { returnObjects: true }) as string[];
    const dataSubList: string[] = t("Privacy_policy.ContentTable.dataSubjectList", { returnObjects: true }) as string[];
    const collectionGenList: string[] = t("Privacy_policy.CollectionGen.collectionGenList", { returnObjects: true }) as string[];
    const collectionGenList2: string[] = t("Privacy_policy.CollectionGen.collectionGenList2", { returnObjects: true }) as string[];
    return (
        <>
            <Container>
                <Typography variant="h4" gutterBottom align="center">{t("Privacy_policy.head")}</Typography>
                <Typography variant="body1" paragraph>{t("Privacy_policy.head1")}</Typography>
                <Typography variant="body2" paragraph>{t("Privacy_policy.head2")}</Typography>
                <Typography variant="h5" gutterBottom>{t("Privacy_policy.ContentTable.ContentHead")}</Typography>
                <List>
                    {contentList && contentList?.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemText primary={item} />
                    </ListItem>
                    ))}
                </List>
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>{t("Privacy_policy.ContentTable.term_def_head")}</Typography>
                    <List>
                        {termList&& termList?.map((term, index) => (
                            <ListItem key={index} disablePadding>
                            <ListItemText primary={term} />
                            </ListItem>
                        ))}
                    </List>
                </Box>
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>{t("Privacy_policy.ContentTable.dataSubjectHead")}</Typography>
                    <List>
                    {dataSubList&& dataSubList?.map((right, index) => (
                        <ListItem key={index} disablePadding>
                        <ListItemText primary={right} />
                        </ListItem>
                    ))}
                    </List>
                </Box>
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>{t("Privacy_policy.DefinitionsHead")}</Typography>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.DefinitionsPara")}</Typography>
                </Box>
                <Typography variant="h6" paragraph>{t("Privacy_policy.privacyFollows.privacyFollowsHead")}</Typography>
                <Box mt={1}>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.privacyFollows.followsP1")}</Typography>
                    <Typography variant="body2" paragraph>{t("Privacy_policy.privacyFollows.followsP2")}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.privacyFollows.followsP3")}</Typography>
                    <Typography variant="body2" paragraph>{t("Privacy_policy.privacyFollows.followsP4")}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.privacyFollows.followsP5")}</Typography>
                    <Typography variant="body2" paragraph>{t("Privacy_policy.privacyFollows.followsP6")}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.privacyFollows.followsP7")}</Typography>
                    <Typography variant="body2" paragraph>{t("Privacy_policy.privacyFollows.followsP8")}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.privacyFollows.followsP9")}</Typography>
                    <Typography variant="body2" paragraph>{t("Privacy_policy.privacyFollows.followsP10")}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.privacyFollows.followsP11")}</Typography>
                    <Typography variant="body2" paragraph>{t("Privacy_policy.privacyFollows.followsP12")}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.privacyFollows.followsP13")}</Typography>
                    <Typography variant="body2" paragraph>{t("Privacy_policy.privacyFollows.followsP14")}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.privacyFollows.followsP15")}</Typography>
                    <Typography variant="body2" paragraph>{t("Privacy_policy.privacyFollows.followsP16")}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.privacyFollows.followsP17")}</Typography>
                    <Typography variant="body2" paragraph>{t("Privacy_policy.privacyFollows.followsP18")}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.privacyFollows.followsP19")}</Typography>
                    <Typography variant="body2" paragraph>{t("Privacy_policy.privacyFollows.followsP20")}</Typography>
                </Box>
                <Box mt={1}>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.privacyFollows.followsP21")}</Typography>
                    <Typography variant="body2" paragraph>{t("Privacy_policy.privacyFollows.followsP22")}</Typography>
                </Box>
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>{t("Privacy_policy.AddressesController.controllerHead")}</Typography>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.AddressesController.controllerp1")}</Typography>
                    <Typography variant="body1" component="div" paragraph>
                        <strong>Inna Bode</strong> <br />
                        Zeppelinstraße 30 <br />
                        67346 Speyer <br />
                        <a href="mailto:info@team-inna.de">info@team-inna.de</a>
                    </Typography>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.AddressesController.controllerp2")}</Typography>
                </Box>
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>Cookies</Typography>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.Cookies.cookieP1")}</Typography>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.Cookies.cookieP2")}</Typography>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.Cookies.cookieP3")}</Typography>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.Cookies.cookieP4")}</Typography>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.Cookies.cookieP5")}</Typography>
                </Box>
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>{t("Privacy_policy.CollectionGen.collectionGenHead")}</Typography>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.CollectionGen.collectionGenP1")}</Typography>
                    <List>
                        {collectionGenList&& collectionGenList?.map((term, index) => (
                            <ListItem key={index} disablePadding>
                            <ListItemText primary={term} />
                            </ListItem>
                        ))}
                    </List>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.CollectionGen.collectionGenHead2")}</Typography>
                    <List>
                        {collectionGenList2&& collectionGenList2?.map((term, index) => (
                            <ListItem key={index} disablePadding>
                            <ListItemText primary={term} />
                            </ListItem>
                        ))}
                    </List>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.CollectionGen.collectionGenP2")}</Typography>
                </Box>
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>{t("Privacy_policy.contactViaWebHead")}</Typography>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.contactViaWebP")}</Typography>
                </Box>
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>{t("Privacy_policy.routineDeleteHead")}</Typography>
                    <Typography variant="body1" paragraph>{t("Privacy_policy.routineDeleteP1")}</Typography>
                </Box>
                <Box mt={4}>
                    <Typography variant="h5" gutterBottom>{t("Privacy_policy.routineDeleteP2")}</Typography>
                    <Typography paragraph variant="body1">{t("Privacy_policy.routineDeleteP3")}</Typography>
                    <Typography paragraph variant="body1">{t("Privacy_policy.routineDeleteP4")}</Typography>
                    <Typography paragraph variant="body1">{t("Privacy_policy.routineDeleteP5")}</Typography>
                    <Typography paragraph variant="body1">{t("Privacy_policy.routineDeleteP6")}
                        <ul dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP7")}}></ul>
                    </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP8")}}></span>
            </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP9")}}></span>
            </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP10")}}></span>
            </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP11")}}></span>
            </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP12")}}></span>
            </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP13")}}></span>
            </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP14")}}></span>
            </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP15")}}></span>
            </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP16")}}></span>
            </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP17")}}></span>
            </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP18")}}></span>
            </Typography>
            <Typography paragraph variant="body2">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP19")}}></span>
            </Typography>
            <Typography paragraph variant="body1">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDelete20")}}></span>
            </Typography>
            <Typography paragraph variant="body2">
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.routineDeleteP21")}}></span>
            </Typography>
        </Box>
            <Typography variant="h5" gutterBottom>{t("Privacy_policy.fbPrivacyPolicyHead")}</Typography>
            <Typography paragraph>{t("Privacy_policy.fbPrivacyPolicyP1")}</Typography>
            <Typography paragraph>{t("Privacy_policy.fbPrivacyPolicyP2")}</Typography>
            <Typography paragraph>
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.fbPrivacyPolicyP3")}}></span>
            </Typography>
            <Typography paragraph>{t("Privacy_policy.fbPrivacyPolicyP4")}</Typography>
            <Typography paragraph>{t("Privacy_policy.fbPrivacyPolicyP5")}</Typography>
            <Typography paragraph>
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.fbPrivacyPolicyP6")}}></span>
            </Typography>
            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>{t("Privacy_policy.googleAnalHead")}</Typography>
            <Typography paragraph>{t("Privacy_policy.googleAnalP1")}</Typography>
            <Typography paragraph>{t("Privacy_policy.googleAnalP2")}</Typography>
            <Typography paragraph>{t("Privacy_policy.googleAnalP3")}</Typography>
            <Typography paragraph>{t("Privacy_policy.googleAnalP4")}</Typography>
            <Typography paragraph>{t("Privacy_policy.googleAnalP5")}</Typography>
            <Typography paragraph>{t("Privacy_policy.googleAnalP6")}</Typography>
            <Typography paragraph>{t("Privacy_policy.googleAnalP7")}</Typography>
            <Typography paragraph>
                <span dangerouslySetInnerHTML={{__html:t("Privacy_policy.fbPrivacyPolicyP8")}}></span>
            </Typography>
            <Typography paragraph>
                Further information and Google’s current privacy policy can be accessed at{' '}
                <Link href="https://www.google.de/intl/de/policies/privacy/" target="_blank" rel="noopener noreferrer">
                https://www.google.de/intl/de/policies/privacy/
                </Link> and{' '}
                <Link href="http://www.google.com/analytics/terms/de.html" target="_blank" rel="noopener noreferrer">
                http://www.google.com/analytics/terms/de.html
                </Link>. Google Analytics is further explained under this link:{' '}
                <Link href="https://www.google.com/intl/de_de/analytics/" target="_blank" rel="noopener noreferrer">
                https://www.google.com/intl/de_de/analytics/
                </Link>.
            </Typography>
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>{t("Privacy_policy.googleAdHead")}</Typography>
                <Typography paragraph>{t("Privacy_policy.googleAdP1")}</Typography>
                <Typography paragraph>{t("Privacy_policy.googleAdP2")} </Typography>
                <Typography paragraph>{t("Privacy_policy.googleAdP3")}</Typography>
                <Typography paragraph>{t("Privacy_policy.googleAdP4")}</Typography>
                <Typography paragraph>
                    {t("Privacy_policy.googleAdP5")}
                    <Link href="https://www.google.de/settings/ads" target="_blank" rel="noopener noreferrer">
                    https://www.google.de/settings/ads
                    </Link>.
                </Typography>
                <Typography paragraph>
                    {t("Privacy_policy.googleAdP6")}
                    <Link href="https://www.google.de/intl/de/policies/privacy/" target="_blank" rel="noopener noreferrer">
                    https://www.google.de/intl/de/policies/privacy/
                    </Link>.
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>{t("Privacy_policy.legalHead")}</Typography>
                <Typography paragraph>{t("Privacy_policy.legalP1")}</Typography>
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>{t("Privacy_policy.legalP2")}</Typography>
                <Typography paragraph>{t("Privacy_policy.legalP3")}</Typography>
                <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>{t("Privacy_policy.legalP4")}</Typography>
                <Typography paragraph>{t("Privacy_policy.legalP5")}</Typography>
                <Typography paragraph>{t("Privacy_policy.legalP6")}</Typography>

            <Typography variant="h6">
                End
            </Typography>

        </Container>
        </>
    )
}
export default Dataproduction;

{/* <div> */}
                {/* <h1>{t("dataProduction_heading")}</h1>
                <p>{t("dataProd_p1")}</p>
                <p>{t("dataProd_p2")}</p>
                <p>As of: July 7, 2023</p>

                <a href="https://datenschutz-generator.de/" target="_blank">
                    <img src={DataProductionImage} alt="data production" />
                </a>

                <h1>{t("data_table_head")}</h1>
                <p
                    dangerouslySetInnerHTML={{ __html: t("data_table_li") }}
                />

                <h1>{t("dataPro_heading_3")}</h1>

                <p>
                    GURU & YOGI LIFESTYLE LLC<br />
                    ALI FIRAT CIGDEM SR SOLE MBR<br />
                    7901 4TH ST N STE 300<br />
                    ST PETERSBURG, FL 33702<br />
                </p>
                <p>ALI FIRAT CIGDEM</p>
                <p>E-mail address:</p>
                <p> <a href="guruandyogi@gmail.com">guruandyogi@gmail.com</a><br /></p>
                <p>Imprint:
                    <a onClick={() => navigate("/terms_condition/imprint")} style={{ color: 'blue', textDecoration: "underline", cursor: 'pointer' }}>Imprint</a></p>
                <br></br>

                <h1>{t("dataPro_relevant_head")}</h1>
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_relevant_p1") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_relevant_ul") }} />

                <p dangerouslySetInnerHTML={{ __html: t("dataPro_P_Note_on") }} />

                <h1>Overview of processing</h1>
                <p>The following overview summarizes the types of data processed and the purposes of their processing and refers to the data subjects.</p>
                <h2>Types of data processed</h2>
                <ul
                    dangerouslySetInnerHTML={{ __html: t("dataPro_type_pross_Li") }}
                />

                <h2>Special Categories of Data</h2>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_category_li") }} />

                <h2>Categories of data subjects</h2>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_subject_category_li") }} />

                <h2>Purposes of processing</h2>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_purpose_process") }} />

                <h1>Safety measures</h1>
                <p>{t("dataPro_Safety_measure_p1")}</p>

                <p>{t("dataPro_Safety_measure_p2")}</p>

                <p>{t("dataPro_Safety_measure_p3")}</p>

                <h1>Transfer of personal data</h1>

                <p>{t("dataPro_transmission_p1")}</p>

                <h1>International data transfers</h1>

                <p>{t("dataPro_international_data_p1")}</p>

                <p>{t("dataPro_international_data_p2")}</p>

                <p>{t("dataPro_international_data_p3")}</p>

                <p>{t("dataPro_international_data_p4")}</p>

                <h1>Deletion of data</h1>
                <p>{t("dataPro_deletion_p1")}</p>

                <p>{t("dataPro_deletion_p2")}</p>

                <h1>Rights of data subjects</h1>
                <p>{t("dataPro_data_subject_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_data_ul") }} />

                <p>{t("dataPro_rights_of_data_head")}</p>
                <p>{t("dataPro_rights_of_data_p")}</p>

                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_rights_of_data_ul") }} />

                <h1>{t("dataPro_cookies_h1")}</h1>
                <p>{t("dataPro_cookies_p1")}</p>
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_cookies_p2") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_cookies_p3") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_cookies_p4") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_cookies_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_generalinfo_p4") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_general_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_furt_serv_p4") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_process_cookies_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_borlabs_cookies_ul") }} />
                <h1>{t("dataPro_business_h1")}</h1>
                <p>{t("dataPro_business_p1")}</p>
                <p>{t("dataPro_business_p2")}</p>
                <p>{t("dataPro_business_p3")}</p>
                <p>{t("dataPro_business_p4")}</p>
                <p>{t("dataPro_business_p5")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_data_process_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_special_cate_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_data_sub_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_legal_basis_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_fur_p2") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_shop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_coaching_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_consulting_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_ul") }} />
                <h1>{t("dataPro_payment_h1")}</h1>
                <p>{t("dataPro_payment_p1")}</p>
                <p>{t("dataPro_payment_p2")}</p>
                <p>{t("dataPro_payment_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_dataprocess_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_data_subject_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_purpose_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_further_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_american_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_apple_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_google_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_master_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_paypay_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_stripe_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_visa_ul") }} />
                <h1>{t("dataPro_provision_h1")}</h1>
                <p>{t("dataPro_provision_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_dp_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_pp_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_lb_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_fio_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_provision_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_collection_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_email_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_united_ul") }} />
                <h1>{t("dataPro_reg_h1")}</h1>
                <p>{t("dataPro_reg_p1")}</p>
                <p>{t("dataPro_reg_p2")}</p>
                <p>{t("dataPro_reg_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_tdp_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_dsub_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_popro_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_lbasis_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_furin_p1") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_regis_p1") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_userpro_p1") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_deletion_p3") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_noobli_p1") }} />
                <h1>{t("dataPro_blogs_h1")}</h1>
                <p>{t("dataPro_blogs_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_blogstypes_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_blogsdata_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_blogspurpose_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_blogslegal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_furinfor_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_retrieve_ul") }} />
                <h1>{t("dataPro_contact_h1")}</h1>
                <p>{t("dataPro_contact_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_contacttypes_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_affectedpersons_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_contactpurpose_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_contactlegals_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_furtproc_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_contactform_ul") }} />
                <h1>{t("dataPro_video_h1")}</h1>
                <p>{t("dataPro_video_p1")}</p>
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_video_p2") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_video_p3") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_video_p4") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_video_p5") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_video_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_video_ap_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_video_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_video_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_videofur_p5") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_video_zoom_ul") }} />
                <h1>{t("dataPro_audio_h1")}</h1>
                <p>{t("dataPro_audio_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_audio_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_audio_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_audio_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_audio_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_audiofur_p5") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_audio_spotify_ul") }} />
                <h1>{t("dataPro_news_h1")}</h1>
                <p>{t("dataPro_news_p1")}</p>
                <p>{t("dataPro_news_p2")}</p>
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_news_double_p1") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_news_del_p1") }} />
                <p>{t("dataPro_regi_p3")}</p>
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_contents_p1") }} />
                <p>{t("dataPro_news_info_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_ap_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_legal_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_oto_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_news_fur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_meas_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_news_active_ul") }} />
                <h1>{t("dataPro_add_h1")}</h1>
                <p>{t("dataPro_add_p1")}</p>
                <p>{t("dataPro_add_p2")}</p>
                <p>{t("dataPro_add_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_add_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_add_ap_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_add_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_add_legal_ul") }} />
                <h1>{t("dataPro_survey_h1")}</h1>
                <p>{t("dataPro_survey_p1")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_survey_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_survey_ap_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_survey_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_survey_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_surveyfur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_googleform_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_typeform_ul") }} />
                <h1>{t("dataPro_web_h1")}</h1>
                <p>{t("dataPro_web_p1")}</p>
                <p>{t("dataPro_web_p2")}</p>
                <p>{t("dataPro_web_p3")}</p>
                <p>{t("dataPro_web_p4")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_web_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_web_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_web_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_web_security_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_web_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_webfur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_googleanly_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_target_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_univer_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_tag_ul") }} />
                <h1>{t("dataPro_online_h1")}</h1>
                <p>{t("dataPro_online_p1")}</p>
                <p>{t("dataPro_online_p2")}</p>
                <p>{t("dataPro_online_p3")}</p>
                <p>{t("dataPro_online_p4")}</p>
                <p>{t("dataPro_online_p5")}</p>
                <p>{t("dataPro_online_p6")}</p>
                <p>{t("dataPro_online_p7")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_security_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_legal_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_online_oto_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_onlinefur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_googleads_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_adsremark_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_utm_ul") }} />
                <h1>{t("dataPro_presence_h1")}</h1>
                <p>{t("dataPro_presence_p1")}</p>
                <p>{t("dataPro_presence_p2")}</p>
                <p>{t("dataPro_presence_p3")}</p>
                <p>{t("dataPro_presence_p4")}</p>
                <p>{t("dataPro_presence_p5")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_presence_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_presence_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_presence_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_presence_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_presencefur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_insta_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_linkedin_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_vimeo_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_utube_ul") }} />
                <h1>{t("dataPro_plugins_h1")}</h1>
                <p>{t("dataPro_plugins_p1")}</p>
                <p>{t("dataPro_plugins_p2")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_plugins_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_plugins_ds_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_plugins_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_plugins_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_pluginsfur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_integ_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_fonts_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_youtube_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_vimeoplugin_ul") }} />
                <h1>{t("dataPro_manage_h1")}</h1>
                <p>{t("dataPro_manage_p1")}</p>
                <p>{t("dataPro_manage_p2")}</p>
                <p>{t("dataPro_manage_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_manage_types_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_manage_ap_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_manage_pop_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_manage_legal_ul") }} />
                <p dangerouslySetInnerHTML={{ __html: t("dataPro_managefur_p1") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_calendly_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_chatgpt_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_googledocs_ul") }} />
                <h1>{t("dataPro_changes_h1")}</h1>
                <p>{t("dataPro_changes_p1")}</p>
                <p>{t("dataPro_changes_p2")}</p>
                <h1>{t("dataPro_changes_h2")}</h1>
                <p>{t("dataPro_changes_p3")}</p>
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_click_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_conv_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_personal_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_profiles_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_reach_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_remark_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_tracking_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_controller_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_process_ul") }} />
                <ul dangerouslySetInnerHTML={{ __html: t("dataPro_targets_ul") }} />

            </div > */}