import { Link } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const Imprint: React.FC = () => {
    const {t} = useTranslation();
    return (
        <>
            <div style={{marginBottom: '5%', }}>
                <h4>{t("imprint_head")}</h4>
                <p>
                    Inna Bode<br />
                    Zeppelinstraße 30<br />
                    67346 Speyer,<br />
                    Germany<br />
                </p>
                {/* <p 
                    dangerouslySetInnerHTML={{__html: t("imprint_address")}}
                /> */}
                <br></br>
                <h4>{t("contact")}</h4>
                <p>
                    Telephone: +49 160 636 8992 <br />
                    E-Mail: <Link
                        href="mailto:info@team-inna.de"
                        sx={{
                        // color: 'inherit',
                        textDecoration: 'none',
                        '&:hover': {
                            color: 'black', // Hover color here
                        },
                        }}
                    >
                        info@team-inna.de
                    </Link><br />
                </p>
                <br></br>
                <h4>{t("imprint_heading_2")}</h4>
                <p>VAT ID: DE318909847</p>
                {/* <p 
                    dangerouslySetInnerHTML={{__html: t("imprint_address")}}
                /> */}
                {/* <p>{t("imprint_heading_3")}</p> */}
                <br></br>

                <h4>{t("imprint_Disclaimer")}</h4>
                <p>{t("imprint_Liability_content")}</p>
                <p>
                    {t("imprint_Liability_content_para")}
                </p>
                <br></br>

                <h4>{t("imprint_Liability_links")}</h4>
                <p>
                    {t("imprint_Liability_links_para")}

                </p>
                <br></br>

                <h4>{t("imprint_copyright")}</h4>
                {/* <p>
                    {t("copyright_1")}
                </p> */}
                <p>
                {t("copyright_p_1")}
                </p>
                <br></br>

                <h4>{t("Liability")}</h4>
                <p>
                    {t("Liability_1")}
                </p>
                <br></br>

                <h4>Gender</h4>
                <p>
                    {t("gender_1")}
                </p>
            </div>
        </>
    );
};

export default Imprint;
