import { AxiosRequestConfig, AxiosResponse } from "axios";
import API from "./API"
import { COURSE_BY_ID_DATA, COURSE_CREATION, COURSE_LIST_DATA, COURSE_PAYMENT_PAGE, COURSE_PAYMENT_TRANSACTION, COURSE_UPDATION, CREATE_TESTIMONIAL, DASHBOARD_DATA_GET, DELETE_COURSE_COVER, DELETE_PROFILE, DELETE_TESTIMONIAL, FILE_UPLOAD, FILE_VIDEO_UPLOAD, FORGOT_PWD_MAIL, GET_ALL_LIST_TESTIMONIAL, GET_BY_TESTIMONIAL, GET_MY_COURSE, GET_USET_BY_ID, REGISTER_USER, RESENT_OTP_ACTIVATE_USER, SIGN_IN_USER, STREAM_VIDEO_GET, UPDATE_TESTIMONIAL, UPDATE_USER, USER_LIST_DATA, VERIFY_OTP_ACTIVATE_USER } from "./APIPath"
import { CourseDefault, GetListTestimonial, Status, TestimonialDefault, UserList, courseList } from "../type";

//API call

export const createUser = async (payload:any): Promise<{status:Status,data:any}> => {
    const config = {
        method:'POST',
        url: REGISTER_USER,
        headers: {
            'Content-Type': 'application/json', // Example header, adjust as needed
        },
        data: payload
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        // Add more specific error handling as needed
        throw new Error(`Failed to create user: ${error?.message}`);
    }
}

export const updateUser = async (payload:any,id:number): Promise<{status:Status,data:any}> => {
    const config = {
        method:'PUT',
        url: UPDATE_USER + id,
        headers: {
            'Content-Type': 'application/json', // Example header, adjust as needed
        },
        data: payload
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        // Add more specific error handling as needed
        throw new Error(`Failed to create user: ${error?.message}`);
    }
}

export const activateUserOtp = async (payload:{otp:string,id:number}): Promise<{status:Status,data:any}> => {
    const config = {
        method: 'POST',
        url: VERIFY_OTP_ACTIVATE_USER,
        headers: {
            'Content-Type': 'application/json', // Example header, adjust as needed
        },
        data:payload
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}>  = await API(config);
         if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        // Add more specific error handling as needed
        throw new Error(`Failed to Activate user OTP: ${error?.message}`);
    }
}

export const ResentOTP = async (payload:any): Promise<{status:Status,data:any}> => {
    const config = {
        method: 'POST',
        url: RESENT_OTP_ACTIVATE_USER,
        headers: {
            'Content-Type': 'application/json', // Example header, adjust as needed
        },
        data:payload
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}>  = await API(config);
         if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        // Add more specific error handling as needed
        throw new Error(`Failed to Resent OTP: ${error?.message}`);
    }
}

export const loginUser = async (payload:{username:string,password:string}): Promise<{status:Status,data:{id:number,token:string,roleName:string}}> => {
    const config = {
        method:'POST',
        url: SIGN_IN_USER,
        header:{
            'Content-Type': 'application/json', // Example header, adjust as needed
        },
        data:payload,
    }
    try{
        const response: AxiosResponse<{status:Status,data:{id:number,token:string,roleName:string}}>  = await API(config);
         if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        // Add more specific error handling as needed
        throw new Error(`Failed to Login user: ${error?.message}`);
    }
}

export const SentFPwd = async (payload:{username:string}): Promise<{status:Status,data:any}> => {
    const config = {
        method:'POST',
        url: FORGOT_PWD_MAIL,
        header:{
            'Content-Type': 'application/json', // Example header, adjust as needed
        },
        data:payload,
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}>  = await API(config);
         if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        // Add more specific error handling as needed
        throw new Error(`Failed to Sent Forgot Password: ${error?.message}`);
    }
}

export const getUserList = async (): Promise<{status:Status,data:UserList[]}> => {
    const config = {
        method:'GET',
        url: USER_LIST_DATA,
        header:{
            'Content-Type': 'application/json', // Example header, adjust as needed
        },
    }
    try{
        const response: AxiosResponse<{status:Status,data:UserList[]}> = await API(config);
         if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        // Add more specific error handling as needed
        throw new Error(`Failed to Get user list: ${error?.message}`);
    }
}

export const getUserData = async (id:number): Promise<{status:Status,data:UserList}> => {
    const config = {
        method:'GET',
        url: GET_USET_BY_ID + id,
        header:{
            'Content-Type': 'application/json', // Example header, adjust as needed
        },
    }
    try{
        const response: AxiosResponse<{status:Status,data:UserList}> = await API(config);
         if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        // Add more specific error handling as needed
        throw new Error(`Failed to Get user: ${error?.message}`);
    }
}

export const dashboardData = async (): Promise<{status:Status,data:any}> => {
    const config = {
        method:'GET',
        url: DASHBOARD_DATA_GET,
        header:{
            'Content-Type': 'application/json', // Example header, adjust as needed
        },
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
         if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        // Add more specific error handling as needed
        throw new Error(`Failed to Get Dashboard data: ${error?.message}`);
    }
}

export const courseContentUpload = async (data:FormData, paramsTypeLocation:string): Promise<{status:Status,data:any}> => {
    const config = {
        method:'POST',
        url: FILE_UPLOAD + paramsTypeLocation,
        header:{
            'Content-Type': 'multipart/form-data',
        },
        data:data
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to upload the file: ${error?.message}`);
    }
}

export const courseVideoContentUpload = async (data:FormData, paramsTypeLocation:string): Promise<{status:Status,data:any}> => {
    const config = {
        method:'POST',
        url: FILE_VIDEO_UPLOAD + paramsTypeLocation,
        header:{
            'Content-Type': 'multipart/form-data',
        },
        data:data
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to upload the video file: ${error?.message}`);
    }
}

export const getCourseById = async(courseId:number): Promise<{status:Status,data:CourseDefault}> => {
    const config = {
        method:'GET',
        url: COURSE_BY_ID_DATA + courseId,
        header:{
            'Content-Type': 'application/json',
        },
    }
    try{
        const response: AxiosResponse<{status:Status,data:CourseDefault}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to upload the file: ${error?.message}`);
    }
}

export const getPaymentLinkURL = async (): Promise<{status:Status,data:{url:string,id:string}|null}> => {
    const config = {
        method : 'post',
        url : COURSE_PAYMENT_PAGE,
        header : {
            'content-Type' : 'application/json',
        }
    }
    try{
        const response: AxiosResponse<{status:Status,data:{url:string,id:string}|null}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to upload the file: ${error?.message}`);
    }
}
export const getListOfCourse = async(userId:number): Promise<{status:Status,data:courseList[]}> => {
    const config = {
        method:'GET',
        url: `${COURSE_LIST_DATA}?userId=${userId}`,
        header:{
            'Content-Type': 'application/json',
        },
    }
    try{
        const response: AxiosResponse<{status:Status,data:courseList[]}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to upload the file: ${error?.message}`);
    }
}

export const getMyCourse = async(userId:number,userName:string): Promise<{status:Status,data:courseList[]}> => {
    const config = {
        method:'GET',
        url: `${GET_MY_COURSE}?userId=${userId}&userName=${userName}`,
        header:{
            'Content-Type': 'application/json',
        },
    }
    try{
        const response: AxiosResponse<{status:Status,data:courseList[]}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to upload the file: ${error?.message}`);
    }
}

export const courseCreation = async (payload:any): Promise<{status:Status,data:any}> => {
    const config = {
        method:'POST',
        url: COURSE_CREATION,
        header:{
            'Content-Type': 'application/json',
        },
        data:payload
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to create course: ${error?.message}`);
    }
}

export const courseUpdation = async (payload:any): Promise<{status:Status,data:any}> => {
    const config = {
        method:'PUT',
        url: COURSE_UPDATION + payload?.courseId,
        header:{
            'Content-Type': 'application/json',
        },
        data:payload
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to Update course: ${error?.message}`);
    }
}

export const deleteCourseCoverFile = async (id:number): Promise<{status:Status,data:any}> => {
    const config = {
        method:'DELETE',
        url: DELETE_COURSE_COVER + id,
        header:{
            'Content-Type': 'application/json',
        }
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to Delete Course cover file: ${error?.message}`);
    }
}

export const getStreamVideoURL = async (contentFileId:number, range?: string) => {
    const config = {
        method:'GET',
        url: `${STREAM_VIDEO_GET}${contentFileId}`,
        headers: {
        Range: range || 'bytes=0-',
        },
        responseType: 'blob' as const, // Set responseType to 'blob' to handle binary data
    }
    try {
        const response: AxiosResponse<Blob> = await API(config);

        if (response.status === 200 || response.status === 206) {
        return response;
        } else {
        throw new Error(`Request failed with status code: ${response.status}`);
        }
    } catch (error: any) {
        throw new Error(`Failed to load the video file: ${error.message}`);
    }
} 

export const entryTestimonial = async (payload:any): Promise<{status:Status,data:any}> => {
    const config = {
        method: payload?.testimonialId == 0 ? 'POST': "PUT",
        url: payload?.testimonialId === 0 ? CREATE_TESTIMONIAL : UPDATE_TESTIMONIAL + payload?.testimonialId,
        header:{
            'Content-Type': 'application/json',
        },
        data:payload
    }
    try{
        const response = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to create or update the testimonial: ${error?.message}`);
    }
}

export const getTestimonialList = async (): Promise<{status:Status,data:GetListTestimonial}> => {
    const config: AxiosRequestConfig = {
        url: GET_ALL_LIST_TESTIMONIAL,
        method:"GET",
        headers:{
            "Content-Type": 'application/json'
        },
    }
    try{
        const response: AxiosResponse<{status:Status,data:GetListTestimonial}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code: ${response.status}`);
        }
    }catch(error:any){
        throw new Error(`Failed to get the testimonial list:${error?.message}`);
    }
}

export const getByTestimonial = async (id:number): Promise<{status:Status,data:TestimonialDefault}> => {
    const config: AxiosRequestConfig = {
        url: GET_BY_TESTIMONIAL + id,
        method:"GET",
        headers:{
            "Content-Type": 'application/json'
        },
    }
    try{
        const response: AxiosResponse<{status:Status,data:TestimonialDefault}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code: ${response.status}`);
        }
    }catch(error:any){
        throw new Error(`Failed to get the testimonial by id:${error?.message}`);
    }
}

export const deleteTestimonial = async (id:number): Promise<{status:Status,data:any}> => {
    const config: AxiosRequestConfig = {
        url: DELETE_TESTIMONIAL + id,
        method:"DELETE",
        headers:{
            "Content-Type": 'application/json'
        },
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code: ${response.status}`);
        }
    }catch(error:any){
        throw new Error(`Failed to delete the testimonial by id:${error?.message}`);
    }
}

export const deleteProfileImage = async (id:number) =>{
    const config: AxiosRequestConfig = {
        url: DELETE_PROFILE + id,
        method:"DELETE",
        headers:{
            "Content-Type": 'application/json'
        },
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code: ${response.status}`);
        }
    }catch(error:any){
        throw new Error(`Failed to delete the Profile:${error?.message}`);
    }
}

export const createAmountTransaction = async (payload:any): Promise<{status:Status,data:any}> => {
    const config = {
        method:'POST',
        url: COURSE_PAYMENT_TRANSACTION,
        header:{
            'Content-Type': 'application/json',
        },
        data:payload
    }
    try{
        const response: AxiosResponse<{status:Status,data:any}> = await API(config);
        if(response.status === 200){
            return response.data;
        }else{
            throw new Error(`Request failed with status code:${response?.status}`)
        }
    }catch(error:any){
        throw new Error(`Failed to create transaction data: ${error?.message}`);
    }
}