import React, { useState, useEffect } from "react";
import { Avatar, AvatarGroup, Box, Divider, ListItemAvatar, ListItemIcon, MenuItem } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
 import Container from "@mui/material/Container";
import { styled} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {
    Tooltip as MuiTooltip
  } from "@mui/material";
  import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
import { AdminPanelSettings, Logout } from "@mui/icons-material";
import SchoolIcon from "@mui/icons-material/School";
import {Menu as MenuMui} from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { RootState } from "../Redux/store/ReduxStore";
import { Loader } from "../Const/Spinner";
import logoAdmin from "../assets/logo/Laughter_logo_blue.png";
import HamburgerMenu from "../assets/icon/hamburger_menu.png";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ListAltIcon from '@mui/icons-material/ListAlt';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubble';

  const drawerWidth = "auto";
  
    const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== "open"
    })<{ open?: boolean }>(({ theme, open }) => ({
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        })
      })
    }));

  function Menu() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const [activeItem, setActiveItem] = React.useState("/");

    const rolesList = useSelector((state:RootState)=>state.auth);

      const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
      const open = Boolean(anchorEl);
      const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

    useEffect(() => {
      if (location.pathname === '/Account') {
        navigate('course');
      }
      // else if(location.pathname === '/Account' ){
      //   navigate('menu-suggestion');
      // }
      // else{
      //   return null;
      // }
    }, [rolesList.role]);

    useEffect(() => {
      const currentLocation = window.location.pathname || "/";
      setActiveItem(currentLocation);
    }, [location]);

    const handleLogout = () => {
      handleClose();
      navigate("/");
    };

  return (
    <div className="APP">
      {
        // (rolesList.token !== "" && rolesList.token ! == null)?
        "kj"?
        <>
          <CssBaseline />
          <AppBar
            position="fixed"
            // open={open}
            sx={{
              backgroundColor: "#fff",
              color: "white",
              height: "100px",
              // background:"transparent"
            }}
          >
            <Toolbar>
              <Typography
                component="div"
                variant="h6"
                color="inherit"
                noWrap
                sx={{ flexGrow: 1 }}
              >
                <img className="logo" alt="logo" src={logoAdmin}
                  style={{ width: 180, height: 130,
                    objectFit: 'contain', // Ensures the image scales properly
                    imageRendering: 'auto', // Defaults to best quality rendering 
                    maxHeight:"100px",
                    maxWidth:"160px"
                  }} loading="lazy"/>
              </Typography>
              <MuiTooltip title="Account">
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <img src={HamburgerMenu} alt="menu" style={{ width: 42, height: 42, fontSize:'40px' }} loading="lazy"/> 
                  {/* <Avatar sx={{ width: 42, height: 42, fontSize:'40px' }}/> */}
                </IconButton>
              </MuiTooltip>
              <MenuMui
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={
                  // handleClose
                  ()=>navigate("/Account/profile")
                }>
                  <Avatar 
                    // sx={{ bgcolor: deepOrange[500] }}
                  /> 
                  Profile
                </MenuItem>
                <Divider />
                {
                  rolesList.role === "Admin" && (
                    <>
                      <MenuItem onClick={()=>navigate("/Account/users")}>
                        <PeopleAltIcon 
                          // sx={{bgcolor: deepOrange[500]}}
                        />
                        Users
                      </MenuItem>
                      <Divider />
                    </>
                  )
                }
                {
                  rolesList.role === "Admin" && (
                    <>
                      <MenuItem onClick={()=>navigate("/Account/testimonials")}>
                        <ChatBubbleOutlineIcon />
                        Testimonials
                      </MenuItem>
                      <Divider />
                    </>
                  )
                }
                <MenuItem onClick={()=>navigate("/Account/course")}>
                  <SchoolIcon /> 
                  Course
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </MenuMui>
            </Toolbar>
          </AppBar>
          <Toolbar />
            <div style={{marginBottom:'3%',backgroundColor:"#f5f5f5"}}>
              <Outlet />
            </div>
        </>
      :
      <Loader />
    }
    </div>
  );
}

export default Menu;
